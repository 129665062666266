@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .order-header {
    @apply mb-8;
  }

  .order-content {
    @apply flex flex-col lg:flex-row w-full mt-8;
  }

  .order-divider {
    @apply h-6 md:h-full w-full md:w-12 xl:w-18;
  }
}
