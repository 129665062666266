@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .radio-box-group {
    @apply w-full;
  }

  .radio-box-group .radio-box:last-of-type {
    @apply border-b border-border-primary;
  }

  .radio-box {
    @apply box-content flex flex-row items-start border-x border-t border-border-primary;
  }

  .radio-box:hover {
    @apply border border-border-active;
  }

  .radio-box:hover + div.radio-box,
  .radio-box:hover + div.radio-box.selected {
    @apply border-t-0;
  }

  .radio-box-group div.radio-box.selected {
    @apply border border-border-active;
  }

  div.radio-box.selected + div.radio-box {
    @apply border-t-0;
  }

  .address-radio-box {
    @apply flex flex-row justify-between;
  }
}
