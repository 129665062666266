@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font size set for rem */

html {
  font-size: 62.5%;
}
